// Retourne si le site est ouvert dans un appareil mobile
export default function isMobile() {
  let condition = false;

  if ((/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
    condition = true;
  }

  return condition;
}
