import $ from 'jquery';
import 'jquery-mask-plugin';

// Le formatage du numéro de téléphone
function formatPhone() {
  $('.js-mask-phone').mask('000 000-0000');
}

// Le formatage du code postal
function formatPostalCode() {
  $('.js-mask-postal-code').mask('Z0Z 0Z0', {
    translation: {
      Z: {
        pattern: /[A-Z, a-z]/,
      },
    },
  });
  // Forcer les majuscules
  $('.js-mask-postal-code').keyup(function keyup() {
    $(this).val($(this).val().toUpperCase());
  });
}

// Le package à appliquer pour les masques
export default function masksPackage() {
  formatPhone();
  formatPostalCode();
}
